import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AvatarCard from '../components/avatar-card';
import ArticleList from '../components/article-list';
import Layout from '../components/page-layout';
export const _frontmatter = {
  "title": "dontpanic 的技术专栏",
  "description": "首页"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function PageLayout(props) {
  return <Layout>
        <AvatarCard />
        <ArticleList />
        {props.children}
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      